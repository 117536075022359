<template>
    <div class="content">
        <!--v-model="checkedKeys"  :expanded-keys="expandedKeys" :auto-expand-parent="autoExpandParent"  :selected-keys="selectedKeys"  -->
        <div class="">
            <!-- <a-tree checkable :tree-data="roleData.role" @expand="onExpand" @select="onSelect"/> -->
            <div class="role">
                <h4 class="global_head_title">角色设置</h4><br>
                <div class="flex">
                    <div v-for="(item,index) in roleData.role" :key="index" style="margin-right:50px">
                        <a-checkbox v-model="item.isChecked" @click="choose(index,item.id)"></a-checkbox>
                        &nbsp;{{item.name}}&nbsp;
                        <a-popconfirm title="确定要删除吗?" @confirm="() => delRole(item.id)">
                            <a-icon type="delete"/>
                        </a-popconfirm>
                    </div>

                    
                    <a @click="addRole"><a-icon type="plus" />添加角色</a>
                </div>
            </div>       
            
            <a-modal v-model="roleVisible" :title="roleTitle" @ok="roleOk">
				<div class="content_row flex">
					<label class="info" for="">角色名称</label>
					<div class="content_right">
						<a-input v-model="roleName" size="large" placeholder="请输入角色名称"/>
					</div>
				</div>
			</a-modal>

            <div style="text-align:left;">
                <h4 class="global_head_title">权限设置</h4>
                <div style="text-align:left" v-for="(item,index) in roleData.group" :key="index">
                    <a-button> 
                        <a-checkbox :indeterminate="item.isAllChecked" :checked="item.isChecked" @change="onCheckAllChange($event,index)">
                          {{item.label}}
                        </a-checkbox>
                    </a-button>
                    <br><br>
                    <a-checkbox-group v-model="item.checkedList" :options="item.children" @change="onChange(index)"/><br><br><br>
                </div>
            </div>
            <div style="text-align:left">
               <a-button @click="save" type="primary">保存</a-button>
            </div>
        </div>
    </div>
</template>
<script>
import {requestXml} from '../../../assets/js/request';
export default {
    data() {
        return {
            roleData: [],
            roleId: "",
            onRoleIndex:0,
            roleVisible: false,
            roleTitle: "添加角色",
            roleName: ""
        };
    },
    mounted: function(){
        this.getRole(1);
    },
    methods:{
        // 获取角色和权限列表 
        getRole(type){
            requestXml("/scrm/Authority/getRoleList","GET",(res) => {
                for(let i=0;i<res.role.length;i++){
                    res.role[i].isChecked = false;
                }
                // 权限group中 添加功能模块 是否全选 是否选中 选中子权限的数组 用于做选中和全选功能
                for(let i=0;i<res.group.length;i++){
                    res.group[i].isAllChecked = false;
                    res.group[i].isChecked = false;
                    res.group[i].checkedList = [];
                    for(let j=0;j<res.group[i].children.length;j++){
                        // 添加功能模块子权限是否选中
                        res.group[i].children[j].isChecked = false;
                    }
                }
                this.roleData = res;
                
                if(type == 1){
                    // 默认选中第一个角色
                    this.roleData.role[0].isChecked = true;
                    this.roleId = this.roleData.role[0].id;
                    this.getRoleDetail(this.roleId)
                }else{
                    this.getRoleDetail(this.roleId)
                }
            })
        },
        // 全选按钮
        onChange(index) {
            let group = this.roleData.group[index];
            group.isAllChecked = !!group.checkedList.length && group.checkedList.length < group.children.length;
            group.isChecked = group.checkedList.length === group.children.length;
        },
        onCheckAllChange(e,index) {
            // console.log(e,index)
            let group = this.roleData.group[index];
            // 选中的放到一个数组
            var checked = [];
            for(let i=0;i<group.children.length;i++){
               checked.push(group.children[i]["value"]);
            }
            // console.log(checked)
            Object.assign(group, {
                checkedList: e.target.checked ? checked : [],
                isAllChecked: false,
                isChecked: e.target.checked,
            });
        },
        // 获取角色的权限详情
        getRoleDetail(id){
            requestXml("/scrm/Authority/getAuthority","POST",(res) => {
                // 全部置为不选中
                console.log(this.roleData);
                for(let i=0;i<this.roleData.group.length;i++){
                    this.roleData.group[i].isAllChecked = false;
                    this.roleData.group[i].isChecked = false;
                    this.roleData.group[i].checkedList = [];
                    for(let j=0;j<this.roleData.group[i].children.length;j++){
                        // 添加功能模块子权限是否选中
                        this.roleData.group[i].children[j].isChecked = false;
                        if(!this.roleData.group[i].children[j].labelOld){this.roleData.group[i].children[j].labelOld=this.roleData.group[i].children[j].label}
                        this.roleData.group[i].children[j].label=this.roleData.role[this.onRoleIndex].name+'-'+this.roleData.group[i].children[j].labelOld;
                    }
                }
                for(let i=0;i<res.group.length;i++){
                    res.group[i] = Number(res.group[i]);
                }
                for(let i=0;i<this.roleData.group.length;i++){
                    // 权限列表中的父权限如果在返回的值中 就置为选中
                    if(res.group.indexOf(this.roleData.group[i].value) != -1){
                        this.roleData.group[i].isChecked = true;
                    }
                    // 选中的子权限
                    for(let j=0;j<this.roleData.group[i].children.length;j++){
                        if(res.group.indexOf(this.roleData.group[i].children[j].value) != -1){
                            this.roleData.group[i].checkedList.push(this.roleData.group[i].children[j].value);
                        }
                    }
                }
            },{"id": id})
        },
        // 选中角色将其他角色置为不选中
        choose(idx,id){
            this.getRoleDetail(id);
            this.onRoleIndex=idx;
            for(let i=0;i<this.roleData.role.length;i++){
                this.roleData.role[i].isChecked = false;
            }
            this.roleData.role[idx].isChecked = true;
            this.roleId = id;
        },
        // 添加角色框显示
        addRole(){
            this.roleVisible = true;
        }, 
        // 添加角色 
        roleOk(){
            if(!this.roleName){
                this.$message.info("请输入角色名称");
                return false;
            }
            requestXml("/scrm/Authority/add","POST",(res) => {
                console.log(res)
                this.roleName = "";
                this.roleVisible = false;
                this.roleId = res.id;
                // this.getRole(2)
                for(let i=0;i<this.roleData.role.length;i++){
                    this.roleData.role[i].isChecked = false;
                }
                for(let i=0;i<this.roleData.group.length;i++){
                    this.roleData.group[i].isAllChecked = false;
                    this.roleData.group[i].isChecked = false;
                    this.roleData.group[i].checkedList = [];
                    for(let j=0;j<this.roleData.group[i].children.length;j++){
                        // 添加功能模块子权限是否选中
                        this.roleData.group[i].children[j].isChecked = false;
                    }
                }
                if(res.id){
                    this.roleData.role.push({"id": res.id, "name": res.name, "isChecked": true})
                }
            },{"name": this.roleName})
        },
        // 删除角色
        delRole(id){
            requestXml("/scrm/Authority/del","POST",() => {
                // console.log(res)
                this.getRole(1);
            },{"id": id})
        },
        // 保存角色权限
        save(){
            var groupArr = [];
            for(let i=0;i<this.roleData.group.length;i++){
                // 如果选中就把父权限加上
                if(this.roleData.group[i].isChecked == true){
                    groupArr.push(this.roleData.group[i].value);
                }
                // 拿到选中的子权限
                for(let j=0;j<this.roleData.group[i].checkedList.length;j++){
                    groupArr.push(this.roleData.group[i].checkedList[j])
                }
            }
            console.log(groupArr)
            if(groupArr.length == 0){
                this.$message.info("请选择一个权限");
                return false;
            }
            // 给角色设置权限
            requestXml("/scrm/Authority/setAuthority","POST",(res) => {
                console.log(res)
                // this.getRole();
            },{"id": this.roleId,"group": groupArr})
           
        }
    }
}
</script>
<style scoped>
    .global_head_title{padding-bottom: 10px;}
    .role{
        text-align: left;
    }
    .role div{
        margin-bottom: 15px;
    }

    .content_row{
		margin: 15px 0 30px;
		font-size: 16px;
		align-items: center;
	}
	.content_row .info{
		width: 90px;
		padding-right: 10px;
	}
	
	/* input ,selsct */
	.content_row .ant-input,.content_row .ant-select{
		width: 380px;
	}
</style>
